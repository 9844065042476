import React from 'react';
import { Typography, Container, Avatar, Link } from '@mui/material'; // Added Link here
import { Box } from '@mui/system';
import { motion } from 'framer-motion';
import avatarImg from '../images/avatar.jpg';

function HiThere() {
    return (
        <Container
            sx={{ textAlign: 'center', mt: 5 }}
            component={motion.div}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 1.2 }}
        >
            <Avatar
                alt='Dmytro Basan - Engineering Manager'
                src={avatarImg}
                sx={{ mx: 'auto', width: 150, height: 150 }}
            />
            <Box mx={3} mt={2}>
                <Typography
                    variant='h4'
                    component={motion.div}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 1.2 }}
                >
                    Hello and welcome!
                </Typography>
                <Typography
                    variant='h6'
                    component={motion.div}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 1.2 }}
                    sx={{ paddingTop: '20px' }}
                >
                    I'm Dmytro, the founder of{' '}
                    <Link href='https://drillup.tech' target='_blank' rel='noopener noreferrer'>
                        DrillUp
                    </Link>{' '} - a platform dedicated to empowering engineers through continuous learning and professional development, and an Engineering Manager at Klarna, committed to pushing the boundaries of thought
                    leadership in technology.
                </Typography>
                <Typography
                    variant='h6'
                    component={motion.div}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 1.2 }}
                >
                    Engage with my podcasts and articles for a curated perspective on industry trends.
                </Typography>
            </Box>
        </Container>
    );
}

export default HiThere;
