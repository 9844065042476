import React from 'react';
import ReactGA from 'react-ga';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { Typography, Container } from '@mui/material';
import { IconButton, Box } from '@mui/material';
import { motion } from 'framer-motion';

const SocialLinks = () => {
    const handleEmailClick = () => {
        ReactGA.event({
            category: 'Email',
            action: 'Clicked',
            label: 'Email Button Click',
        });
        window.location.href = 'mailto:me@dmytrobasan.com';
    };

    const handleLinkedInClick = () => {
        ReactGA.event({
            category: 'LinkedIn',
            action: 'Clicked',
            label: 'LinkedIn Button Click',
        });
    };

    const handleInstagramClick = () => {
        ReactGA.event({
            category: 'Instagram',
            action: 'Clicked',
            label: 'Instagram Button Click',
        });
    };

    const handleTextSelect = () => {
        ReactGA.event({
            category: 'Email',
            action: 'Selected',
            label: 'Selected me@dmytrobasan.com',
        });
    };

    const handleTextCopy = () => {
        ReactGA.event({
            category: 'Email',
            action: 'Copied',
            label: 'Copied me@dmytrobasan.com',
        });
    };

  return (
    <Container sx={{textAlign: "center", mt: 5}}>
        <Box mx={2} mt={2}>
            <Typography variant="h4" component={motion.div}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2, duration: 1.2 }}
            >
                Contact Me
            </Typography>
            <Typography
                variant="h6"
                component={motion.div}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 1.2 }}
                sx={{
                    color: 'primary.main', // Use your primary color
                    padding: '8px', // Add padding for better visibility
                    borderRadius: '4px', // Add rounded corners
                    display: 'inline-block', // Ensure it's displayed inline
                }}
                onCopy={handleTextCopy}
                onMouseUp={handleTextSelect}
                onTouchEnd={handleTextSelect}
                onSelect={handleTextSelect}
            >
                me@dmytrobasan.com
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <IconButton
                    color="primary"
                    href="https://www.linkedin.com/in/dmytro-basan/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ fontSize: '5rem', width: '5rem', height: '5rem' }} // Adjust the size as needed
                    onClick={handleLinkedInClick}
                >
                    <LinkedInIcon sx={{ fontSize: '3rem' }} />
                </IconButton>
                <IconButton
                    color="primary"
                    target="_blank"
                    onClick={handleEmailClick}
                    rel="noopener noreferrer"
                    sx={{ fontSize: '5rem', width: '5rem', height: '5rem' }} // Adjust the size as needed
                >
                    <EmailIcon sx={{ fontSize: '3rem', color: 'black' }} />
                </IconButton>
                <IconButton
                    color="primary"
                    href="https://www.instagram.com/basan_dima/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleInstagramClick}
                    sx={{ fontSize: '5rem', width: '5rem', height: '5rem' }} // Adjust the size as needed
                >
                    <InstagramIcon sx={{ fontSize: '3rem', color: 'red' }} />
                </IconButton>

            </Box>
            <Typography
                variant="h9"
                component={motion.div}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 1.2 }}
                sx={{
                    padding: '8px', // Add padding for better visibility
                    display: 'inline-block', // Ensure it's displayed inline
                }}
            >
                &copy; 2024 Dmytro Basan
            </Typography>
        </Box>
    </Container>
  );
};

export default SocialLinks;