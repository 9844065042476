// App.js
import React from 'react';
import ReactGA from 'react-ga';
import AboutMe from './pages/Overview';
import HiThere from './pages/HiThere';
import Podcasts from './pages/Podcasts';
import SocialLinks from './pages/SocialLinks';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
    ReactGA.initialize('G-QB2FLX1C8B');
    return (
        <div className="app">
            <HiThere />
            <AboutMe />
            <Podcasts />
            <SocialLinks />
        </div>
    );
}

export default App;
