import React, { lazy, Suspense } from 'react';
import { Typography, Container, Box } from '@mui/material';
import { motion } from 'framer-motion';
import Loader from './Loader'; // Assuming this is a simple spinner/loader component

const PodcastItem = lazy(() => import('./PodcastItem.js'));  // Assuming this component contains logic for each podcast item

const Podcasts = () => {
  const podcasts = [{
    url: 'https://evolutionjobs.com/exchange/evo-nordics-408-the-power-of-empathy-in-tech-leadership/',
    title: 'Evo Nordics #408 – The Power Of Empathy In Tech Leadership',
    description: 'Join host Gemma Thomason in this episode of Evo Nordics as she explores the power of empathy in tech leadership. Gemma is joined by three esteemed guests: Alexander Bolmstedt, Project Manager at QD Sverige AB, Dmytro Basan, Engineering Manager at Klarna, and Hans Sandstrom, Engineering Manager at Instabee. Together, they discuss the role of empathy in driving successful tech teams, fostering innovation, and creating a positive work culture. Gain valuable insights from these tech leaders and discover the transformative potential of empathy in the world of technology.',
    image: 'https://evolutionjobs.com/wp-content/uploads/2023/07/8.png',
    date: '2023-07-07'
  },
  {
    url: 'https://evolutionjobs.com/exchange/evo-nordics-420-unleashing-potential-klarna-berlins-high-performing-engineering-teams/',
    title: 'EVO Nordics #420: Unleashing the potential of Klarna Berlin’s high-performing engineering teams',
    description: 'Join us in this episode of Evo Nordics as we delve into the world of high-performing engineering teams at Klarna Berlin. Hosted by Chris Asbridge, we’re joined by Dmytro Basan, Engineering Manager at Klarna, and Tomasz Dacewicz, Senior Engineering Manager at Klarna. Discover the secrets behind unleashing the potential of engineering teams and gain insights into their strategies for success. Tune in for a deep dive into the world of Klarna’s engineering prowess and how they drive innovation in the ever-evolving tech landscape.',
    image: 'https://evolutionjobs.com/wp-content/uploads/2023/08/Nordics-Podcast-Artwork-6.png',
    date: '2023-08-23'
  },
  {
      url: 'https://evolutionjobs.com/exchange/evo-nordics-434-how-to-be-an-empathetic-leader/',
      title: 'Evo Nordics #434 – How To Be An Empathetic Leader',
      description: 'In Evo Nordics #434, host Gemma Thomason explores “How To Be An Empathetic Leader” with industry leaders Florian Barth, Head of Engineering In-Store & Retail Relationships at Klarna, Dmytro Basan, Engineering Manager at Klarna, and Saman Ilkhanizadeh, Director at Teledyne FLIR. Delve into the keys to empathetic leadership in the corporate world, drawing on the experiences and insights of these distinguished professionals from Klarna and Teledyne FLIR. Gain valuable perspectives on fostering empathy in leadership for a more inclusive and effective work environment.',
      image: 'https://evolutionjobs.com/wp-content/uploads/2023/11/Nordics-Podcast-Artwork-3.png',
      date: '2023-11-23'
  },
  {
      url: 'https://www.perituspartners.co.uk/blog/2023/09/how-best-to-prepare-for-a-live-coding-interview',
      title: 'How best to prepare for a live coding interview',
      description: "Are you currently gearing up for a live coding challenge? Perhaps you've recently faced one that didn't go as planned. Live coding challenges, although contrived scenarios, wield considerable influence over your engineering prowess due to their unexpected nature and the accompanying heightened stress. To provide you with real support, we've collaborated with three seasoned managers who have not only conquered numerous challenges themselves but have also participated in evaluating many more. They're here to unveil their top tips to aid you in preparing, maintaining focus, and ideally excelling in your upcoming live session. A huge thank you goes out to our guests: Hernán Magrini from Miro, Nicolò Pignatelli from the team at GetYourGuide, and Dmytro Basan, an Engineering Manager from Klarna. With that, let's delve into the heart of the matter and unveil the most effective strategies to prepare for a live coding challenge.",
      image: 'https://www.perituspartners.co.uk/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBL0FLVFE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--ed0193dc424239694e210777c38d7d5a52a3baac/How%20best%20to%20prepare%20for%20a%20live%20coding%20interview.png',
      date: '2023-09-27'
  },
      {
          url: 'https://www.youtube.com/watch?v=Z8xG1J3WhKk',
          title: 'Beyond technical expertise: Unveiling the human side of engineering management',
          description: 'Ever wondered what it takes to lead an engineering team that is motivated, productive, and continuously evolving? It\'s time to travel beyond the realms of technical expertise and delve into the human side that adds life and colours to the canvas of engineering management.\n' +
              'In this rousing talk, you\'ll uncover the essence of understanding each member\'s unique strengths, fostering an environment that thrives on empathy, and striking the perfect balance between technical and people skills. Get ready to witness inspiring real-life stories that will make you rethink the traditional paradigms and empower you to cultivate a growth mindset.\n' +
              'Join Dmytro as he challenges common stereotypes and explores the authentic, human qualities that elevate engineering leadership to new heights. Together, let\'s spark an engineering revolution, infused with openness, trust, and the passion to create a lasting impact on teams and projects.',
          image: 'https://i.ytimg.com/vi/Z8xG1J3WhKk/maxresdefault.jpg',
          date: '2023-12-20'
      },
      {
          url: 'https://evolutionjobs.com/exchange/evo-nordics-463-what-is-a-productive-team/',
          title: 'Evo Nordics #463 – What Is A Productive Team',
          description: "Join host Gemma Thomason in Evo Nordics #463 for an insightful exploration of “What is a Productive Team.” Accompanying Gemma are distinguished guests Håkan Sandström (Manager Digital Sales Channel at ATG), Oscar Mwanandimai (Engineering Manager at Hello Fresh), and Dmytro Basan (Engineering Manager at Klarna). Discover the secrets of fostering productivity, effective team collaboration, and management strategies within ATG, Hello Fresh, and Klarna. Unlock the keys to creating and maintaining high-performing teams in the Nordic business landscape.",
          image: 'https://evolutionjobs.com/wp-content/uploads/2024/01/Nordics-Podcast-Artwork-8.png',
          date: '2024-01-09'
      },
      {
          url: 'https://evolutionjobs.com/exchange/evo-nordics-500-where-to-improve-as-a-leader/',
          title: 'Evo Nordics #500 – Where To Improve As A Leader',
          description: "Join Gemma Thomason as she explores strategies for leadership improvement in the latest episode. Featuring insights from Rui Baiao, Engineering Manager at Volvo Cars; Gunnar Mortensen, Development Manager at Eika; and Dmytro Basan, Engineering Manager at Klarna. Discover actionable tips and techniques to enhance your leadership skills, navigate challenges, and drive success in your organization.",
          image: 'https://evolutionjobs.com/wp-content/uploads/2024/04/Nordics-Podcast-Artwork.png',
          date: '2024-04-04'
      },
      {
          url: 'https://evolutionjobs.com/exchange/evo-nordics-535-leading-through-tech-disruption-navigating-rapid-industry-change/',
          title: 'Evo Nordics #535- Leading Through Tech Disruption – Navigating Rapid Industry Change',
          description: "In this episode, host Gemma Thomason explores how leaders in the tech industry are navigating rapid change. Hans Sandström, Engineering Manager at Instabee, and Bert Oja and Dmytro Basan, both Engineering Managers at Klarna, share their insights on managing tech disruption and driving innovation in their organizations. Learn how Instabee and Klarna are leveraging technology to lead through industry transformation, adapt to shifts in the market, and set the pace in a fast-evolving digital landscape",
          image: 'https://evolutionjobs.com/wp-content/uploads/2024/09/Nordics-Podcast-Artwork-545.png',
          date: '2024-09-19'
      },
      {
          url: 'https://evolutionjobs.com/exchange/evo-nordics-535-leading-through-tech-disruption-navigating-rapid-industry-change/',
          title: 'Evo Nordics #543 – Cultivating A Growth Mindset',
          description: "In this insightful episode, host Georgia Benton is joined by an expert panel of leaders, including Dmytro Basan, Engineering Manager at Klarna, Maria Kirilenko, Founder in Residence at Antler, Fredrik Engdahl, CEO of Boneo, and Nadeem Akhtar Mohd, Engineering Manager II at SumUp. Together, they explore the importance of cultivating a growth mindset in today’s fast-evolving tech landscape. Learn how these experts drive innovation, foster company culture, and implement leadership strategies to propel their teams toward success. This episode is perfect for entrepreneurs and leaders in tech.",
          image: 'https://evolutionjobs.com/wp-content/uploads/2024/10/Podcast-Artwork-Sweden.png',
          date: '2024-10-14'
      },
  ]

    const sortedPodcasts = podcasts.sort((a, b) => new Date(b.date) - new Date(a.date));


    return (
    <Container sx={{textAlign: "center", mt: 5}}>
    <Box sx={{ mb: 3 }}>
      <Typography variant="h4" component={motion.div} initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.2, duration: 1.2 }}>
          My Thoughts Corner
      </Typography>
    </Box>
    <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }, gap: 2 }}>
      <Suspense fallback={<Loader />}>
        {sortedPodcasts.map((podcast, index) => (
          <PodcastItem podcast={podcast} key={index} />
        ))}
      </Suspense>
    </Box>
  </Container>
  );
};

export default Podcasts;
