import React from 'react';
import { Typography, Container } from '@mui/material';
import { Box } from '@mui/system';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
function AboutMe() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Container sx={{textAlign: "center", mt: 5}}>
      <Box mx={3} mt={2}>
        <Typography variant="h4" component={motion.div} 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 1.2 }}
        >
          About Me
        </Typography>

        <Box sx={{ paddingTop: '20px', display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }, gap: 2 }}>
          <Typography
              variant="h6"
              component={motion.div}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 1.2 }}
              sx={{ textAlign: isMobile ? 'center' : 'left' }}
          >
            As the founder of DrillUp and an accomplished engineering manager, I specialize in leading and mentoring cross-functional teams. My diverse technical background empowers me to tackle complex challenges across industries like hospitality, web3, finance, analytics, and payments. I bring expertise in technologies such as Node.js, React.js, AWS, and architecting large-scale systems with diverse designs.
          </Typography>
          <Typography
              variant="h6"
              component={motion.div}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 1.2 }}
              sx={{ textAlign: isMobile ? 'center' : 'left' }}
          >
            Throughout my career, I've delivered high-impact solutions that have fueled organizational growth and optimization. At Klarna, I orchestrated the development of the AI-powered Magic Import feature, enhancing the quality of imported orders by 30% and achieving annual cost savings of approximately $3 million. I managed two cross-functional teams of over 15 engineers, fostering a culture of innovation and collaboration. My efforts led to a 50% improvement in order accuracy and a 70% reduction in transaction list load time, significantly boosting customer satisfaction.
          </Typography>
        </Box>

        <Typography  variant="h6" component={motion.div}
                     initial={{ opacity: 0, y: -10 }}
                     animate={{ opacity: 1, y: 0 }}
                     transition={{ delay: 0.2, duration: 1.2 }}
                     sx={{paddingTop: '50px'}}
        >
          Beyond the corporate realm, I actively contribute to the tech community as a podcast speaker and have been featured in insightful interviews for industry articles. In addition to my professional pursuits, I have a deep passion for exploring new places, reflecting my love for travel and the diversity it brings. Let's connect and chat about tech, travel, and everything in between!
        </Typography>
      </Box>
    </Container>
  );
}

export default AboutMe;